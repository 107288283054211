import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { MDContent } from "../components/Content";

export const ProcessOfAMortgagePageTemplate = ({
  image,
  title,
  description,
  footbuttonlink,
  footbuttontext,
  foottitle,
  steps,
  helmet,
}) => (
  <div className="content">
    {helmet || ""}
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `center`,
        minHeight: 450,
      }}
    >
      <h2 className="has-text-weight-bold is-size-1 has-text-white">{title}</h2>
    </div>
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          {Array.isArray(steps)
            ? steps.map((step, idx) => (
                <div key={`${idx}-step`} className="column is-2">
                  <a href={`#step${idx + 1}`}>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: step.image,
                        alt: `Step ${idx + 1}: ${step.title}`,
                        style: { maxWidth: 250, width: "100%" },
                      }}
                    />
                  </a>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>

    <div className="section">
      <div className="container has-text-centered">
        <p style={{ lineHeight: 2 }}>{description}</p>
      </div>
    </div>
    <div className="section">
      <div className="columns is-centered is-vcentered has-text-centered">
        <div className="column is-4 ">
          <span className="title is-3 ">{foottitle}</span>
        </div>
        <div className="column is-4">
          <a href={footbuttonlink} className="button is-primary">
            {footbuttontext}
          </a>
        </div>
      </div>
    </div>
    {Array.isArray(steps)
      ? steps.map((step, idx) => (
          <section id={`step${idx + 1}`} key={`${idx}-step`}>
            <div
              className="full-width-image-container margin-top-0"
              style={{
                backgroundImage: `url(${
                  !!step.banner.childImageSharp
                    ? step.banner.childImageSharp.fluid.src
                    : step.banner
                })`,
                backgroundPosition: `top left`,
                minHeight: 450,
              }}
            >
              <div className="section">
                <div
                  style={{
                    display: "flex",
                    height: "150px",
                    lineHeight: "1",
                    justifyContent: "space-around",
                    alignItems: "left",
                    flexDirection: "column",
                  }}
                >
                  <h1
                    className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                    style={{
                      color: "white",
                      padding: "0.25em",
                    }}
                  >
                    Step {idx + 1}: {step.title}
                  </h1>
                  <hr className={"has-background-dark"} />
                </div>
              </div>
            </div>
            <div className="section">
              <div className="container">
                <div className="columns">
                  <div className="column is-4">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: step.image,
                        alt: `Step ${idx + 1}: ${step.title}`,
                        style: { maxWidth: 300, width: "100%" },
                      }}
                    />
                  </div>
                  <div className="column is-8">
                    <div>
                      <MDContent content={step.body} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))
      : null}
  </div>
);

ProcessOfAMortgagePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  foottitle: PropTypes.string,
  footbuttontext: PropTypes.string,
  footbuttonlink: PropTypes.string,
  steps: PropTypes.array,
};

const ProcessOfAMortgagePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ProcessOfAMortgagePageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        description={frontmatter.description}
        foottitle={frontmatter.foottitle}
        footbuttontext={frontmatter.footbuttontext}
        footbuttonlink={frontmatter.footbuttonlink}
        steps={frontmatter.steps}
        helmet={
          <Helmet titleTemplate={`Process Of A Mortgage | Get Started`}>
            <title>{`Process Of A Mortgage`}</title>
            <meta name="description" content={`Process Of A Mortgage`} />
          </Helmet>
        }
      />
    </Layout>
  );
};

ProcessOfAMortgagePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProcessOfAMortgagePage;

export const ProcessOfAMortgagePageQuery = graphql`
  query ProcessOfAMortgagePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
        foottitle
        footbuttontext
        footbuttonlink
        steps {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 250, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          banner {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          body
        }
      }
    }
  }
`;
